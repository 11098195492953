#myBtn 
{
    display: none;
    position: fixed; 
    bottom: 20px; 
    right: 30px; 
    z-index: 99; 
    border: none; 
    outline: none; 
    background-color: white; 
    color: black; 
    cursor: pointer; 
    padding: 5px;
    border-radius: 10px; 
    font-size: 18px;
    border: 3px solid black; 
  }
  
  #myBtn:hover 
  {
    transform: scale(1.1);
    transition: all 1s;
    background-color: rgb(230, 230, 230);
  }

  .more-img
  {
    width: 100%;
    height: 100%;
    max-width: 40px;
    max-height: 40px;
  }

  .navbar-toggler
  {
    border: none;
  }

  #nav-button1:hover, #nav-button2:hover, #nav-button3:hover, #nav-button4:hover, #nav-button5:hover
  {
    color: #d9d9d9 !important;

  }