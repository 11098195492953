/* Slideshow container */
.slideshow-container
{
  max-width: 1000px;
  position: relative;
  margin: auto;
  text-align: center;
  overflow: hidden;
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.slider-img
{
  width: auto;
  height: 80%;
  max-height: 550px;
  margin-left: auto;
  margin-right: auto;
}

.mySlides
{
  text-align: center;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 5.5s;
}

@keyframes fade {
  from {opacity: .75} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {font-size: 11px}
}
  
#popup-img-id
{
    width: 100vw;
    min-height: 100vh;
    height: max-content;
    position: absolute;
    z-index: 2000;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.95);
    display: none;
}

#popup-img-holder
{
  width: auto;
  height: 80%;
  max-height: 80%;
  margin: auto;
  top: 50%;
  left: 50%;
  background-color: #212529;
  position: fixed;
  transform: translate(-50%, -50%);
}

.dot1 
{
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot1:hover 
{
  background-color: #717171;
}