@font-face {
  font-family: font;
  src: url('ArimaKoshi-Regular.otf') format('ttf');
}
*
{
    font-family: font, sans-serif;
    
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar when scrolled */
.navbar-holder.scrolled {
  background-color: #0088DE; /* Make background solid */
  transition: background-color 0.3s ease-in-out;
}

.navbar-holder.scrolled .nav-link {
  color: #fff !important; /* Make text white */
}

.navbar-holder.scrolled #nav-logo,
.navbar-holder.scrolled #nav-more {
  filter: brightness(0) invert(1); /* Make images white */
}

/* Optional: Adjust logo image if needed */
.navbar-holder.scrolled #nav-logo {
  content: url('logo-images/matart-logo-white.png');
}

.navbar-holder.scrolled #nav-more {
  content: url('logo-images/more-white.png');
}


/* Custom styles for the dots in the slider */
.slick-dots {
  position: absolute;
  bottom: -25px; /* Position the dots below the slider */
  width: 100%;
  display: flex !important;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.slick-dots li {
  margin: 0 10px; /* Space between dots */
}

.slick-dots li button {
  width: 5px; /* Width of the dots */
  height: 5px; /* Height of the dots */
  border-radius: 50%; /* Make the dots round */
  background-color: #ccc; /* Default color of the dots */
  transition: background-color 0.3s ease;
}

.slick-dots li button:before {
  content: ''; /* Remove the default button text */
  display: inline-block;
  width: 5px; /* Width of the dots */
  height: 5px; 
  background-color: inherit; /* Inherit the button background color */
  border-radius: 50%; /* Ensure the inner content is round */
}

.slick-dots li.slick-active button {
  background-color: #000; /* Color of the active dot */
}


.slideshow-container {
  position: relative; /* Ensure the container is positioned relative */
  padding-bottom: 60px; /* Adjust to make enough space for the dots */
}
@media (max-width: 767px) {
  .gallery-container {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media (min-width: 767px) {
  .gallery-container {
    padding-right: 120px;
    padding-left: 120px;
  }
}