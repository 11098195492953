
.admin-panel .container {
    height: 100vh; /* Full viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: none;
  }
  
  .admin-panel .cucc {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .admin-panel .csordogal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container without distortion */
    z-index: -1; /* Places the background image behind other content */
  }
  
  .admin-panel .login-bg {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    text-align: center;
  }
  
  .admin-panel .logo {
    max-width: 70%;
    margin-bottom: 20px;
  }
  
  .admin-panel .btn1 {
    background-color: #000;
    color: #fff;
    width: 90%;
    padding: 10px;
    border-radius: 25px;
    margin-top: 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .admin-panel .btn1:hover {
    background-color: #666;
    color: black;

  }
  
  .admin-panel .form-control {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .admin-panel .tallozas {
    margin-bottom: 20px;
  }
  
  .admin-panel  .error-message {
    color: red;
    margin-top: 10px;
  }
  