*
{
    font-family: font, sans-serif;
    
}

body
{
    background: rgb(255,156,0);
    background: linear-gradient(0deg, #dcdcdc 0%, #fafafa 100%);
    overflow-x:hidden;
    
}

h1
{
    text-align: center;
    margin-top: 5%;
    font-weight: bold;
    margin-bottom: 5%;
}
h3
{
    margin-bottom: 1%;
    text-align: center;
}

.navbar-holder
{
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    right: 0;
    font-size: 24px;
    color: white;
    background-color: rgba(0, 139, 222, 0.702);
    z-index: 1000;
}

.colpics
{
    width:  70%;
    height: auto;
    border: 4px solid #8b3103;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.6);
    margin-bottom: 20px;
    cursor: pointer;
}

.logo
{
    width: 15%;
    height: auto;
    min-width: 180px;
    padding-left: 20px;
}

.csordogal
{
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    z-index: -1000;
}

.column
{
    background-color: none;
}



*,*:after,*:before{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}



.uzi
{
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    text-align: justify;
    font-size: 2vh;

    background: rgba(255, 255, 255, 0.65);
    border-radius: 16px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4.3px);
    -webkit-backdrop-filter: blur(4.3px);
}

.kerdes
{
    font-size: 3vh;
    border-right: 4px solid black;
    font-weight: bold;
}

#welcome-text
{
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 4vh;
}

.auto-input
{
    font-weight: bold;
}

  .welcome-long
{
    font-size: 3vh;
    text-align: justify;
    margin-bottom: 5%;
}

.welcomelong-contact
{
    text-decoration: none;
    color: blue;
}

#box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2px;
    color: white;
    font-family: 'Raleway';
    font-size: 2.5rem;
  }
  .gradient-border {
    --borderWidth: 2px;
  }
  .gradient-border:after {
    content: '';
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(90deg, #49aae4, #417ea1);
    z-index: -1;
    animation: animatedgradient 15s ease alternate infinite;
    background-size: 300% 300%;
  }
  
  
  @keyframes animatedgradient {
      0% {
          background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
  }


.footer-class
{
    
  background-color: #49aae4;
  text-align: center;
  font-size: 3vh;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footerlogo
{
  width: 40%;
  height: auto;
}

.footerhullam
{
  width: 100%;
  height: auto;
}