.cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5rem;
    width: 90%;
    max-width: 1000px;
    margin: 10vh auto;
}

.card {
    border-radius: 16px;
    box-shadow: 0 30px 30px -25px rgba(#4133B7, .25);
    max-width: 300px;
}

.information {
    background-color: white;
    padding: 1.5rem;

    .tag {
        display: inline-block;
        background-color: var(--c-titan-white);
        color: var(--c-indigo);
        font-weight: 600;
        font-size: 0.875rem;
        padding: 0.5em 0.75em;
        line-height: 1;
        border-radius: 6px;
        & + * {
            margin-top: 1rem;
        }
    }

    .title {
        font-size: 1.5rem;
        color: var(--c-void);
        line-height: 1.25;
        & + * {
            margin-top: 1rem;
        }
    }

    .info 
    {
        color: var(--c-charcoal);
        & + * 
        {
            margin-top: 1.25rem;
        }
    }



}

article
{
	transition: all 0.5s;
	cursor: pointer;
	min-height: 300px;
	background-color: white;
}

article:hover
{
	transform: scale(1.15);
	box-shadow: 0 8px 8px rgba(0, 0, 0, 0.6);
}